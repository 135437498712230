.projects {
	width: 90%;
	margin: auto;
	flex-wrap: wrap;
	margin-top: 10px;

	@include breakpoint-up(large) {
		width: 90%;
		margin-top: 60px;
	}

	&__spinner {
		width: 50px;
		height: 50px;
		position: absolute;
		top: 50%;
		left: 50%;
		color: $primary;
		opacity: 0.8;
		animation: spin infinite 2s linear;
	}
}
