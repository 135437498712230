.home {
	flex-direction: column;
	margin-top: 15%;
	position: relative;

	@include breakpoint-up(large) {
		flex-direction: row;
		margin-top: 175px;
	}

	&__left {

		@include breakpoint-up(large) {
			padding-left: 240px;
			//margin-left: auto;
			//width: 715px;
		}

		// Profile image
		&__profile {
			width: 140px;
			height: 140px;
			border-radius: 50%;
			object-fit: cover;
			box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.25);

			@include breakpoint-up(large) {
				width: 250px;
				height: 250px;
				margin-bottom: 15px;
				box-shadow: none;
				box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.25);
			}
		}

		&__mail, &__location{
			font-size: $small;

			@include breakpoint-down(small) {
				position: absolute;
				bottom: -50px;
				left: 50%;
				transform: translate(-50%, 0);
			}

			svg {
				width: 18px;
				height: 18px;
				margin-right: 20px;
				margin-left: 30px;

				@include breakpoint-down(small) {
					width: 12px;
					height: 12px;
					margin-right: 10px;
					margin-left: 0
				}
			}
		}

		&__mail {
			margin-bottom: 5px;
		}

		&__location {
			@include breakpoint-down(small) {
				position: absolute;
				bottom: -70px;
			}
		}

	}

	&__separator {
		@include breakpoint-up(large) {
			height: 460px;
			border: 0.5px solid black;
			margin-left: 60px;
			margin-right: 70px;
		}
	}

	&__right {
		margin-top: 20px;

		//large
		@include breakpoint-up(large) {
			margin-top: 0;
		}

		// Intro title
		&__intro {
			font-family: 'Cormorant Garamond', serif;
			font-size: 25px;
			text-align: center;

			@include breakpoint-up(large) {
				font-size: $x-large;
				text-align: left;
			}
		}

		// Social sites icons group
		&__icons {
			margin-top: 10px;
			max-width: max-content;

			@include breakpoint-down(small) {
				margin-right: auto;
				margin-left: auto;
			}

			// Icons
			a {
				margin-right: 13px;

				> svg {
					width: 15px;
					height: 15px;
					color: $ic-bg;

					@include breakpoint-up(large) {
						width: 20px;
						height: 20px;
					}
				}

			}

		}

		// About myself description
		&__about {
			font-size: $small;
			max-width: 90%;
			margin: auto;
			margin-top: 15px;
			text-align: center;

			@include breakpoint-up(large) {
				font-size: $med;
				max-width: 600px;
				margin-top: 20px;
				text-align: left;
			}
		}

	}

	//icons hover
	&__facebook {
		svg:hover path {
			color: #3b5998;
		}
	}

	&__github {
		svg:hover path {
			color: $primary;
		}
	}

	&__twitter {
		svg:hover {
			color:  #1da1f2;
		}
	}

	&__linkedin {
		svg:hover {
			color: #0077b5;
		}
	}

}

