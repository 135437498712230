* {
	box-sizing: border-box;
	margin: 0; padding: 0;
}

html {
	font-family: 'Open Sans', sans-serif;
//	font-family: 'Josefin Sans', sans-serif;
}

body {
	font-size: $small;
	color: #000000;
	//background-color: #efefef;

	@include breakpoint-up(large) {
		font-size: $med;
	}
}

a:link, a:visited, a:hover, a:active {
	text-decoration: none;
	color: #000000;

}

.flex {
	display: flex;

	&-ac {
		align-items: center;
	}
	&-sb {
		justify-content: space-between;
	}

	&-sa {
		justify-content: space-around;
	}

	&-c {
		justify-content: center;
	}
	
	&-dc {
		flex-direction: column;
	}
}

.h2 {
	margin: 0;
	padding: 0;
	font-weight: 400;
}

.hide {
	visibility: hidden;
	//opacity: 0;
	//display: none;
}
