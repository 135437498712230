// Primary colours

//$primary: #753D7E;
$primary: #5c32bd;

// Secondary colours
$gray: #a8a8a8;

// Icon background colour
$ic-bg: #000000b8;

// Font sizes
$small: 12px;
$med: 15px;
$large: 16px;
$x-large: 50px;
$nav: 15px;

// Font weights
$regular: 400;
$medium: 500;
$bold: 600;

