.about {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 8%;

	@include breakpoint-up(large) {
		flex-direction: row;
		align-items: unset;
		justify-content: center;
		margin-top: 110px;
		//margin: 80px 20px 20px 0px;
	}

	&__img {
		width: 150px;
		height: 150px;
		overflow: hidden;

		@include breakpoint-up(large) {
			width: 410px;
			height: 595px;
			margin-right: 80px;
		}

		img {
			width: 100%;
			max-height: 100%;
			border-radius: 50%;
			object-fit: cover;

			@include breakpoint-up(large) {
				border-radius: 0%;

			}

		}
	}

	&__description {
		margin: 20px;
		text-align: justify;

		@include breakpoint-up(large) {
			margin: 0;
			margin-top: 62px;
		}

		&__heading {
			padding: 0 0 15px 0;
			font-family: 'Cormorant', serif;
			font-size: 30px;
			font-weight: $regular;

			@include breakpoint-up(large) {
				font-size: $x-large;
				padding-bottom: 20px;
			}
		}

		&__p{
			font-size: $small;

			@include breakpoint-up(large) {
				max-width: 430px;
				font-size: $large;
			}
		}

	}
}
