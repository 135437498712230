.nav {
	width: 85%;
	height: 8%;
	font-family: 'Open Sans', sans-serif;
	font-size: $nav;
	position: relative;
	margin: auto;
	z-index: 999;

	// Left side cursive logo
	&__left {
		font-family: 'Ma Shan Zheng', cursive;
		font-size: 15px;

		@include breakpoint-up(large) {
			font-size: 20px;
		}
	}

	// Nav element highlighting
	&__element {
		a {
			position: relative;
		}

		&__highlight, a::after {
			content: "";
			position: absolute;
			bottom: -7px;
			left: 0;
			width: 100%;
			height: 2px;
			background: $primary;
			transition: all 300ms;
		}

		a::after {
			opacity: 0;
		}

		a:hover::after {
			opacity: 100;
			
		}

		@include breakpoint-up(large) {
			padding-right: 50px;

		}

	}

	// Desktop
	&__webNav {
		@include breakpoint-down(small) {
			display: none;
		}

	}

	// Mobile hamburger menu
	&__mobileNav {
		&__links {
			position: absolute;
			top: 30px;
			right: 0;
			width: 100%;
		}

		.nav__element {
			margin-bottom: 10px;
		}

		@include breakpoint-up(large) {
			display: none;
		}
	}

}
