.projectCard {
	width: 100%;
	height: 185px;
	padding: 15px 24px;
	margin: 0 10px 20px 10px;
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	background-color: #fff;
	transform: scale(1);
	transition: all 300ms;
	
	@include breakpoint-up(large) {
		width: 440px;
		height: 260px;
		padding: 25px 32px;
		margin: 0 25px 40px 25px;
	}

	&__title {
		font-size: 18px;
		margin-bottom: 10px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		@include breakpoint-up(large) {
			font-size: 25px;
			max-height: 64px;
			margin-bottom: 15px;
		}
	}

	&__desc {
		overflow: hidden;
		height: 48px;
		font-size: 12px;
		line-height: 16px;
		margin-bottom: 10px;

		@include breakpoint-up(large) {
			height: 60px;
			font-size: 14px;
			margin-bottom: 15px;
			line-height: 20px;
		}
	}

	&__lang {
		margin-bottom: 12px;
		height: 20px;

		> svg {
			height: 20px;
			margin-right: 5px;
		}

		@include breakpoint-up(large) {
			margin-bottom: 15px;
			height: 24px;

			> svg {
				margin-right: 8px;
			}
		}
	}

	&__btn {
		button {
			width: 92px;
			height: 26px;
			font-size: 12px;
			font-weight: $medium;
			border-radius: 20px;
			margin-right: 12px;
			color: $primary;
			background-color: #fff;
			border: 2px solid $primary;
			transition: all 200ms;


			@include breakpoint-up(large) {
				width: 127px;
				height: 37px;
				margin-right: 15px;
				border-radius: 32px;
				font-size: 14px;
			}
			
			svg {
				margin-right: 5px;
				width: 12px;
				height: 12px;

				@include breakpoint-up(large) {
					width: 16px;
					height: 16px;
				}
			}
			
		}

		button:enabled:hover {
			color: #fff;
			background-color: $primary;
			opacity: 0.8;
			cursor: pointer;
		}

		button:disabled {
			cursor: default;
			border: 0px;
			background-color: #fff;
			color: $gray;
			border: 2px solid $gray;
		}

	}

}
